import React from 'react'
import './label.css'
import logo from '../../images/search.png'

function Label(props:any){

    return(
        <section className="label">
           <div className="label-courses">
                <h5>All Courses</h5>

            </div>
            <div className="label-dropdown" >
                    <select className="dropdown" name="drop" id="drop" onClick={()=>props.onSort()}>
                            <option value="volvo" >  High to Low </option>
                            <option value="saab">Low to High</option>

                    </select>
            </div>

            <div className="label-search"> 
            <form action="#"> 
                <input type="text"placeholder=" Search Here."name="search" />
                </form> 
            <div>
                <img src={logo} alt=""/>
            </div>
            </div> 
       </section> 

    );
}
export default Label;