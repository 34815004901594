import React from 'react'
import './display.css'
import logo from '../../images/Mask Group 1.png'

function Display(){
    return(
            <section className="d1">
                <h5>Display Latest Courses on <br/>React</h5>
                <img src={logo} alt=""/>
            </section>
    );
}
export default Display;