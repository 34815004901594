const data={ 
    products:
        [
            {
                "title":"HashedIn React",
                "author":"Rohit Khurana",
                "price" : 685,
                "tags" : "React",
                "qty" : 0
            },
            
            {
                "title":"HashedIn HTML",
                "author":"Ronit Roy",
                "price" : 654,
                "tags" : "Html",
                "qty" : 0
            },
            {
                "title":"Hashedin Python",
                "author":"Amir Sheikh",
                "price" : 505,
                "tags" : "Python",
                "qty" : 0
            }
            ,
            {
                "title":"Hashin Docker",
                "author":"Atif Naru",
                "price" : 355,
                "tags" : "Docker",
                "qty" : 0
            },
            {
                "title":"Hashedin Git",
                "author":"Suryansh",
                "price" : 325,
                "tags" : "Git",
                "qty" : 0
            },
            {
                "title":"Hashedin AWS",
                "author":"Abhish",
                "price" : 300,
                "tags" : "AWS",
                "qty" : 0
            },
           
           
           
     ],
    };
    export default data;