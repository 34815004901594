import React from 'react'
import './header.css'
import logo from '../../images/Logo-2.png'
import logo2 from '../../images/shopping-cart.svg'
import logo3 from '../../images/noun_profile_2068277.svg'
import {Link,Switch,Route} from 'react-router-dom'



function Header()
{
    return(
        <div>
            <header className="header container">
                    <div className="logo1">
                        <img src={logo}alt="logo"/>
                    </div>

                    <div></div>

                    <div className="course">
                        <h4>Courses</h4>
                    </div>

                    <div  className="wish">
                        <h5>My Wishlist</h5>
                    </div>
  
                    <div>
                        <a href="">
                        <img className="im" src={logo2} alt=""/>
                        </a>  
                    </div>

                    <div>
                        <img className="im" src={logo3} alt=""/>
                    </div>
            </header>
        </div>
    )
}

export default Header;