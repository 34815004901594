import React, { useState } from 'react';
import './App.css';
import Header from './containers/header/Header'
import Display from './containers/displaybar/Display1'
import Label from './containers/label/Label'
import Card from './containers/cards/Card'
import data from './cardData'
import Basket from './containers/basket/Basket'



let mainTitle:any=null;
let myObj=[
  {
    "title":"ignore",
    "author":"",
    "price" :0,
    "tags" : "Sijo M Peter",
    "qty" : 0
},
];
function App() {

        interface Idata{
          title:string;
          author:string;
          price: any;
          tags:string;
        }

      const {products}=data;
      const [show, setShow]=useState(false);
      const [cartItems, setCartItems]=useState(products);
      
      const [cartItems_sort, setCartItems_sort]=useState(products);
      

      const closeModel=()=>setShow(false);
      
      function GetSortOrder(pro:any) {    
        return function(a:any, b:any) {    
              console.log("sdad");
                if (a.price > b.price) {    
                    return 1;    
                } else if (a.price< b.price) {    
                    return -1;    
                }    
                return 0;    
              }    
          } 
      

      const onSort=(args:any)=>
      {   
        setCartItems_sort(cartItems_sort.sort(GetSortOrder(cartItems_sort)));
        console.log(cartItems_sort);
        onAdd(null);
      }



      const onAdd= (product:any) => {
              if(product==null){console.log("null"); return;}
              console.log(product.title);

      const exist= myObj.find(( x => x.title == product.title ));
      console.log(exist);
      console.log("1");
      if(exist)
      { 
        mainTitle=exist;
        console.log("exist")
          
        setCartItems(cartItems.map((x:any) => x.title===product.title ? {...exist, qty: exist.qty +1} :x ));
      }
      else 
      {
        setCartItems([ ...cartItems, { ...product, qty:1}]);
          console.log("not  exist")
          mainTitle=product;
          myObj.push(mainTitle);
      }
    }; 
      
    let counterVariable=-1;
    const ncard=(val:any)=>
      {
        counterVariable++;
        return (
          <Card
            id={counterVariable}
            title={val.title}
            author={val.author}
            price={val.price}
            tag={val.tags} 
            onAdd={onAdd}
            products={cartItems_sort}
          >
          </Card>
        );
      }

      return (
        
      <div className="App">

      <main className="section-container">
          <Header></Header>
          <Display></Display>
          <Label onSort={onSort}></Label>
          
          <div className="carrds">
         
              {cartItems_sort.map(ncard)}

              {console.log(myObj)}
              <Basket cartItems={cartItems} title={mainTitle} arr={myObj}></Basket> 
          </div>
        </main>
      </div>
  );
}

export default App;
