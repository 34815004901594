import logo from '../../images/download.png'
import React from 'react'
import './basketc.css'

let totalPrice=0;
function basketCard(props:any){
    

    if(props.title=="ignore")
        return(
            <div></div>
        );
    

    else
    {
    return(      
              <div key={props.title} className="logo"> 
                {
                 <div> <img src={logo} alt=""/> 
                  <h4>
                  {props.title} <br/> <span> {"sasasas"} </span>RS.{props.price} /- 
                  </h4>
                  <hr></hr>
                  </div> 
                }
                </div>
                 
                   
    );

}}

export default basketCard;