import React, { useState } from 'react'
import './card.css'
import CardData from "../../cardData"
import logo from '../../images/download.png'
import logo2 from '../../images/Path 28.svg'
import data from '../../cardData'
import Basket from '../basket/Basket'
import logo3 from '../../images/Group 37.svg'
import Label from '../label/Label'

function Cards(props:any)
{

    const A= ()=>{
       
    }
    return(
            <div className="card"> 
            
                <div>
                <img src={logo} alt=""/>
                </div>
                <div>
                    <h4>{props.title}</h4>
                </div>
                <div>
                    <h5>{props.author}</h5>
                </div>

                <div className="tags">
                    <h4>{props.tag}</h4>

                </div>
                <div className="tags2">
                    <h4>{props.tag}</h4>

                </div>


                <div className="star-image">
                    <img src={logo2} alt=""/>
                </div>
                <div>
                    <h4>RS.{props.price}/-</h4>
                </div>
                <div>
                    <h5>-</h5>
                </div>
                <div className="btn">
                <button onClick={ ()=>props.onAdd(props.products[props.id])}>
                    Add to Cart</button>
                    
                </div>
                

            </div>        
    );
}
export default Cards;