import { timeLog } from 'console';
import React from 'react'
import './basket.css'
// import './basketCard'
import BasketCard from './basketCard';
//let myObj:any=[];

function Basket(props:any)
{
         console.log(props.arr)
        
          let totalPrice=0;

          const bfun=(val:any)=>
          {

            return (
                <BasketCard
                    title={val.title}
                    author={val.author}
                    price={val.price}
                    tag={val.tag} 
                >
                </BasketCard>
            )

          }

        /*  const onAdd= (product:any) => {

          /*  console.log(product.title);
            // console.log(product.title);

            const exist= myObj.find(( x => x.title == product.title ));
            console.log(exist);
            console.log("1");
            // console.log(exist)
            // console.log(exist)
            if(exist)
            { 
              console.log("exist")
                // console.log("hello")
               myObj.map(x => x.title==product.title ? {...exist, qty: exist.qty +1} :x )
             
            }
            else 
            {
                 console.log("not  exist");
                 myObj.push(props.title);
                // console.log(newItems);
                ///newsetCartItems([ ...cartItems, { ...product, qty:1}]);
            }
            
          };
          */
       //   let title=props.arr;
      //  console.log(title);
    
    //    myObj.push(title);

    return(
        
        
        <div className="basket"> 

            <h3><u>Your Cart Details</u></h3>
            {props.arr.length ===1 && <div className="empty"> Your Cart is empty right now please add courses in the cart from list.</div>}
             
            {props.arr.map(bfun)}



        </div>
    );

}

export default Basket;




            
//             {/* {myObj.map(bfun)} */}

            
// {/* 
//             <div key={title.title}>
//                     <div> <h5> {title.title} RS.{title.title} /- </h5> </div>
//                     Total: Rs. {title.Totalprice+=title.price}/-
//             </div>    */}
// {/* 
//              */}


// {cartItems.length ===0 && <div>Cart empty</div>}
            // {
            //     cartItems.map((item:any)=>
                    
            //         <div key={item.title}>
            //             {/* console.log("fsdf") */}
            //             <div><h4>{item.title}
            //                 </h4></div>
            //         <div>

            //         </div>
                    
            //     </div>
            // )
            // };